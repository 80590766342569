import { h, Component } from 'preact';
import { DoubloonAnalytics } from './doubloon-analytics.js';
import packageJson from '../package.json';
let doubloonAnalytics = new DoubloonAnalytics();

const isLoadingImage = {
  main: true,
  bump: true,
  currentOffer: true
};

// Stack of all of the offers added to our cart, and the associated checkout variants
// Useful for undo, and tracking
const cartStack = [];
const getUpsellOffersInCartStack = () => {
  let upsellOffers = [];
  cartStack.slice(1).forEach(cartStackItem => {
    if (cartStackItem.offers) {
      upsellOffers = [
        ...upsellOffers,
        ...cartStackItem.offers
      ];
    }
  });
  return upsellOffers;
}

export class DoubloonFunnel extends Component {

  constructor() {
    super();
    this.state = {
      funnelName: null,
      discount: null, 
      expireTime: null,
      storeUrl: null,
      privacyPolicyLink: null,
      contactUsLink: null,
      shippingZones: null,
      mainOffer: null,
      bumpOffer: null,
      bumpOfferAccepted: false,
      upsellOffer: null,
      downsellOffer: null,
      additionalUpsells: [],
      // TODO: Turn the Offer Navigation into a tree data structure
      // Offers Index is the offer we are on (Main Offer, Upsell #1, Additional Upsell #1, etc...)
      offersIndex: 0,
      // Offers DownSell Index is the index of the Downsell we are currently on
      // E.g Upsell #1's Downsell, Additional Upsell #1s Downsell. This can only be 0 or 1 currently, unless
      // We start supporting multiple downsells
      offersDownsellIndex: 0,
      checkoutVariants: [],
      payYourOwnPrice: {
        value: 0,
        variantId: '',
        shippingZoneVariantIds: {}
      },
      isTimeExpired: false,
      isCheckingOut: false,
      checkoutUrl: ''
    };
  }

  componentDidMount() {
    // Get our props and apply them to state
    let newState = {
      ...this.state,
      funnelName: this.props.json.funnelName,
      discount: this.props.discount,
      expireTime: this.props.expireTime,
      storeUrl: this.props.json.storeUrl,
      privacyPolicyLink: this.props.json.privacyPolicyLink,
      contactUsLink: this.props.json.contactUsLink,
      shippingZones: this.props.json.shippingZones,
      mainOffer: this.props.json.mainProduct,
      bumpOffer: this.props.json.orderBump
    };
    if ((!newState.discount || newState.discount.length === 0) && this.props.json.discount) {
      newState.discount = [this.props.json.discount];
    }
    if (this.props.json.mainProduct.isOfferPayYourOwnPrice) {      
      // Make sure to assign our state pay your own price to the default values
      newState.payYourOwnPrice = {
        value: this.props.json.mainProduct.payYourOwnPriceProduct.variantValue,
        variantId: this.props.json.mainProduct.payYourOwnPriceProduct.variantId,
        shippingZoneVariantIds: this.props.json.mainProduct.payYourOwnPriceProduct.shippingZoneVariantIds
      }
    }
    if (this.props.json.upsell) {
      newState.upsellOffer = this.props.json.upsell;
    }
    if (this.props.json.downsell) {
      newState.downsellOffer = this.props.json.downsell;
    }
    if (this.props.json.additionalUpsells) {
      newState.additionalUpsells = this.props.json.additionalUpsells;
    }

    if (this.props.json.analytics) {
      doubloonAnalytics = new DoubloonAnalytics(this.props.json.analytics);
      setTimeout(() => {
        doubloonAnalytics.doubloonLoad(
          newState.funnelName,
          newState.discount,
          newState.storeUrl
        );
      }, 100);
    }

    this.setState(newState);

    // Do some logging
    console.log(`Oceanfront Online Doubloon Version: ${packageJson.version}`, newState);

    // If we have an expire time, kickoff a timeout to update it
    if (newState.expireTime) {
      let updateExpireClock = () => {
        let expireClockElement = document.querySelector('#expire-clock');
        if (!expireClockElement) {
          setTimeout(() => updateExpireClock(), 1000);
          return;
        }
        let dateInMillis = Date.now();
        if (dateInMillis >= newState.expireTime) {
          expireClockElement.innerHTML = 'Sorry! You ran out of time.';
          this.setState({
            ...this.state,
            isTimeExpired: true
          });
          return;
        } else {
          // Find the distance between now and the count down date
  		    const distance = newState.expireTime - dateInMillis;
          const days = Math.floor(distance / (1000 * 60 * 60 * 24));
          const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  		    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      	  const seconds = Math.floor((distance % (1000 * 60)) / 1000);
          expireClockElement.innerHTML = `<div>Time Remaining:</div><div>${days} days ${hours} hours ${minutes} minutes ${seconds} seconds</div>`;
        }
        setTimeout(() => updateExpireClock(), 1000);
      };
      setTimeout(() => updateExpireClock(), 100);
    }
  }

  getCurrentOffer() {
    if (this.state.offersIndex === 0) {
      return this.state.mainOffer;
    }

    if (this.state.offersIndex === 1) {
      if (this.state.offersDownsellIndex === 0) { 
        return this.state.upsellOffer;
      } else {
        return this.state.downsellOffer;
      }
    }

    const additionalUpsellIndex = this.state.offersIndex - 2;
    if (this.state.offersDownsellIndex === 0) {
      return this.state.additionalUpsells[additionalUpsellIndex].upsell;
    } else {
      return this.state.additionalUpsells[additionalUpsellIndex].downsell;
    }
  }

  acceptOffer() {
    let addCheckoutVariants = (offer) => {
      // Track that we accepted this offer
      doubloonAnalytics.acceptOffer(offer);

      offer.shopifyProducts.forEach(shopifyProduct => {
        let checkoutVariant = {
          variantId: shopifyProduct.variantId,
          shippingZoneVariantIds: shopifyProduct.shippingZoneVariantIds,
          quantity: parseInt(shopifyProduct.quantity, 10)
        }

        if (shopifyProduct.selectedVariantOption) {
          checkoutVariant.variantId = shopifyProduct.selectedVariantOption.variantId;
          checkoutVariant.shippingZoneVariantIds = shopifyProduct.selectedVariantOption.shippingZoneVariantIds;
          shopifyProduct.selectedVariantOption = null;
        }

        // Check if we already have this variant, if so, only increase it's quantity
        let foundVariant = this.state.checkoutVariants.some(variant => {
          if (variant.variantId === checkoutVariant.variantId) {
            variant.quantity += parseInt(shopifyProduct.quantity, 10);
            return true;
          }
        });

        // If we did't find the variant already, add it to our cart
        if (!foundVariant) {
          this.state.checkoutVariants.push(checkoutVariant);
        }
      });
    };

    if (this.state.offersIndex > 0) {
      // Accept the additional offer
      let currentOffer = this.getCurrentOffer();
      cartStack.push({
        offers: [currentOffer],
        checkoutVariants: [...this.state.checkoutVariants]
      });
      addCheckoutVariants(currentOffer);
    } else {
      // Track the Main, Pyop, and Bump offer
      let checkoutVariants = [
        ...this.state.checkoutVariants
      ];
      let offers = [];

      // Add the main offer
      offers.push(this.state.mainOffer);
      addCheckoutVariants(this.state.mainOffer);

      // Add the PYOP Offer
      if (this.state.payYourOwnPrice && this.state.payYourOwnPrice.value > 0) {
        // Track our Pay Your Own Price Offer
        let pyopTitle = 'Pay Your Own Price';
        let pyopOffer = {
          title: pyopTitle,
          shoppingCartTitle: pyopTitle,
          offerPrice: this.state.payYourOwnPrice.value,
          shopifyProducts: [{
            productName: pyopTitle,
            quantity: 1,
            variantId: this.state.payYourOwnPrice.variantId,
            shippingZoneVariantIds: this.state.payYourOwnPrice.shippingZoneVariantIds,
            variantName: this.state.payYourOwnPrice.value,
            variantOptionName: pyopTitle
          }]
        };
        offers.push(pyopOffer);
        addCheckoutVariants(pyopOffer);
      }

      // Add the Bump Offer
      if (this.state.bumpOffer && this.state.bumpOfferAccepted) {
        offers.push(this.state.bumpOffer);
        addCheckoutVariants(this.state.bumpOffer);
      }

      cartStack.push({
        offers,
        checkoutVariants
      });
    }

    isLoadingImage.currentOffer = true;

    // Check if we should load the additional offer
    let newOffersIndex = this.state.offersIndex;
    if (this.state.offersIndex >= 1) {
      let tempOffersIndex = this.state.offersIndex + 1;
      let additionalUpsellsIndex = tempOffersIndex - 2;
      while (additionalUpsellsIndex < this.state.additionalUpsells.length) {
        if (!this.state.additionalUpsells[additionalUpsellsIndex].requiresBumpOffer) {
          // Found the next offer, break
          newOffersIndex = tempOffersIndex;
          additionalUpsellsIndex = this.state.additionalUpsells.length;
        } else if (this.state.additionalUpsells[additionalUpsellsIndex].requiresBumpOffer && this.state.bumpOfferAccepted) {
          // found the next offer, break
          newOffersIndex = tempOffersIndex;
          additionalUpsellsIndex = this.state.additionalUpsells.length;
        } else {
          tempOffersIndex = tempOffersIndex + 1;
          additionalUpsellsIndex = tempOffersIndex - 2;
        }
      }
    } else {
      newOffersIndex += 1;
    }


    this.setState({
      ...this.state,
      offersIndex: newOffersIndex
    });
  }

  undoOffer() {
    // Undo the offer
    let undidCartElement = cartStack.pop();
    let newOffersIndex = this.state.offersIndex;
    let newOffersDownsellIndex = this.state.offersDownsellIndex;
    // If we are on a downsell, don't decrement
    if (this.state.offersDownsellIndex > 0) {
      newOffersDownsellIndex = newOffersDownsellIndex - 1;
    } else {
      // Check if we are going back to the main offer
      if (this.state.offersIndex === 1) {
        newOffersIndex = 0;
      } else if (this.state.offersIndex > 1) {
        // Do some custom logic to handle upsell to additional upsells :p
        // Set newOffersIndex to 1 by default, and it will be swapped for something higher if found
        newOffersIndex = 1;
        let tempOffersIndex = this.state.offersIndex - 1;
        let additionalUpsellsIndex = tempOffersIndex - 2;
        while (additionalUpsellsIndex >= 0) {
          if (!this.state.additionalUpsells[additionalUpsellsIndex].requiresBumpOffer) {
            // Found the next offer, break
            newOffersIndex = tempOffersIndex;
            additionalUpsellsIndex = -1;
          } else if (this.state.additionalUpsells[additionalUpsellsIndex].requiresBumpOffer && this.state.bumpOfferAccepted) {
            // found the next offer, break
            newOffersIndex = tempOffersIndex;
            additionalUpsellsIndex = -1;
          } else {
            tempOffersIndex = tempOffersIndex - 1;
            additionalUpsellsIndex = tempOffersIndex - 2;
          }
        }
      }
    }

    // Track that we undo this offer
    if (undidCartElement.offers) {
      undidCartElement.offers.forEach(offer => {
        doubloonAnalytics.undoOffer(offer);
      });
    } else {
      doubloonAnalytics.undoOffer(null);
    }

    this.setState({
      ...this.state,
      offersIndex: newOffersIndex,
      offersDownsellIndex: newOffersDownsellIndex,
      checkoutVariants: undidCartElement.checkoutVariants
    });
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  shopifyCheckout() {
    // Track all the offers we added to our cart, in our cart stack
    cartStack.forEach(cartElement => {
      if (cartElement.offers) {
        cartElement.offers.forEach(offer => {
          doubloonAnalytics.addToCart(offer);
        });
      }
    });

    // Start our checkout URL
    let storeUrl = this.state.storeUrl;
    if (storeUrl.endsWith('/')) {
      storeUrl = storeUrl.slice(0, storeUrl.length - 1);
    }
    let checkoutUrl = `${storeUrl}/cart/`;

    // Check our shipping zones
    // By default, we will just use the default variants on the checkout Variants array
    let foundShippingZoneKey = '(Default)';
    let foundShippingZoneCheckoutVariants = JSON.parse(JSON.stringify(this.state.checkoutVariants));
    if (this.state.shippingZones && this.state.shippingZones.length > 0) {
      for (let i = 0; i < this.state.shippingZones.length; i++) {
        const shippingZoneKey = this.state.shippingZones[i].name;
        const shippingZoneCheckoutVariants = JSON.parse(JSON.stringify(this.state.checkoutVariants));

        const allVariantsWorkedWithShippingZone = shippingZoneCheckoutVariants.every(variant => {
          if (!variant.shippingZoneVariantIds || !variant.shippingZoneVariantIds[shippingZoneKey]) {
            return false;
          }
          variant.variantId = variant.shippingZoneVariantIds[shippingZoneKey];
          return true;
        });

        if (allVariantsWorkedWithShippingZone) {
          foundShippingZoneCheckoutVariants = shippingZoneCheckoutVariants;
          foundShippingZoneKey = shippingZoneKey;
          i = this.state.shippingZones.length;
        }
      }
    }

    // Add each of our variants and their quantities from our shipping zones
    for (let i = 0; i < foundShippingZoneCheckoutVariants.length; i++) {
      let variant = foundShippingZoneCheckoutVariants[i];
      checkoutUrl += `${variant.variantId}:${variant.quantity}`;
      if (i < foundShippingZoneCheckoutVariants.length - 1) {
        checkoutUrl += ',';
      }
    }

    // Add our discount(s) (if we have them) 
    if (this.state.discount) {
      this.state.discount.forEach((discount, index) => {
        if (index == 0) {
          checkoutUrl += `?`;
        } else {
          checkoutUrl += `&`;
        }
        checkoutUrl += `discount=${discount}`;
      });
    }

    // Add a cart permalink attribute to doubloon, that corresponds to the name of the funnel
    if (this.props.doubloonRef) {
      if (!(new URL(checkoutUrl)).search) {
        checkoutUrl += `?`;
      } else {
        checkoutUrl += `&`;
      }
      checkoutUrl += `attributes[doubloon-ref]=${this.props.doubloonRef}`;
    }
    if (this.state.funnelName) {
      if (!(new URL(checkoutUrl)).search) {
        checkoutUrl += `?`;
      } else {
        checkoutUrl += `&`;
      }
      checkoutUrl += `attributes[Doubloon]=${this.state.funnelName}`;
    }

    // Track that we started Checkout
    doubloonAnalytics.initiateCheckout();

    // Start loading, and navigate to the checkout URL
    this.setState({
      ...this.state,
      isCheckingOut: true
    });

    let navigationLog = `Navigating to Shopify Checkout...`;
    if (this.state.shippingZones) {
      navigationLog = `Navigating to Shopify Checkout... | Shipping Zone: ${foundShippingZoneKey} |`;
    } 
    console.log(navigationLog, checkoutUrl);

    setTimeout(() => {
      if (this.props.isPreview){
        console.log('Preview Mode! Use this Link for Shopify Checkout', checkoutUrl);
        this.setState({
          ...this.state,
          checkoutUrl: checkoutUrl
        })
      } else {
        window.location.replace(checkoutUrl);
      }
    }, 125);
  }

  render() {
    return (
      <div>
        {this.state.expireTime ? (
          <div>
            <div class="info-bar">
              <div id="expire-clock"></div>
            </div>
            <div class="info-bar--spacer"></div>
          </div>
        ) : ''}

      <main>
        {(() => {
          let renderOutput = (
            <div>
              Loading...
            </div>
          );

          if (this.state.offersIndex === 0) {
            if (!this.state.mainOffer) {
              return renderOutput;
            }

            // Main Offer & Order Bump
            renderOutput = (
              <div class="offer">
                <div class="offer__header">
                  {this.state.mainOffer.title ? (
                    <h1 class="offer__title">{this.state.mainOffer.title}</h1>
                  ) : ''}

                  {this.state.mainOffer.imageUrl ? (
                    <div>
                      <div style={{display: isLoadingImage.main ? "block" : "none"}}>
                        <div class="loading-spinner"></div>
                      </div>
                      <div style={{display: isLoadingImage.main ? "none" : "block"}}>
                        <img key={this.state.mainOffer.imageUrl} 
                          class="offer__image" 
                          src={this.state.mainOffer.imageUrl} 
                          onLoad={() => {
                            isLoadingImage.main = false;
                            this.setState({
                              ...this.state,
                            })
                          }} />
                      </div>
                    </div>
                  ) : ''}

                  {/* Main Offer Price, or Pay Your Own Price */}
                  {(() => {
                    if (!this.state.mainOffer.isOfferPayYourOwnPrice) {
                      return (
                        <h2>Only {this.state.mainOffer.originalPrice ? (<s>${this.state.mainOffer.originalPrice}</s>) : ''} ${this.state.mainOffer.offerPrice}!</h2>
                      );
                    }

                    // Allow the user to pay their own price
                    return (
                      <div class="offer__pyop-select">
                        <label>
                          <h3>
                            Name Your Own Price:
                          </h3>
                          <div>
                            <select disabled={this.state.isTimeExpired || this.state.isCheckingOut}
                              value={JSON.stringify({
                                value: this.state.payYourOwnPrice.value,
                                variantId: this.state.payYourOwnPrice.variantId,
                                shippingZoneVariantIds: this.state.payYourOwnPrice.shippingZoneVariantIds
                              })}
                              onChange={event => {
                                let selectedPyop = JSON.parse(event.target.value);
                                this.setState({
                                  ...this.state,
                                  payYourOwnPrice: selectedPyop
                                });
                              }}>
                              <option value={JSON.stringify({
                                 value: this.state.mainOffer.payYourOwnPriceProduct.variantValue,
                                 variantId: this.state.mainOffer.payYourOwnPriceProduct.variantId,
                                 shippingZoneVariantIds: this.state.mainOffer.payYourOwnPriceProduct.shippingZoneVariantIds
                                })}>
                                ${this.state.mainOffer.payYourOwnPriceProduct.variantValue}
                              </option>
                              {(() => {
                                let variantOptions = [];
                                this.state.mainOffer.payYourOwnPriceProduct.variantOptions.forEach(variantOption => {
                                  variantOptions.push((
                                    <option value={JSON.stringify({
                                      value: variantOption.variantValue,
                                      variantId: variantOption.variantId,
                                      shippingZoneVariantIds: variantOption.shippingZoneVariantIds
                                    })}>
                                      ${variantOption.variantValue}
                                    </option>
                                  ));
                                });
                                return variantOptions
                              })()}
                            </select>
                          </div>
                        </label>

                        {this.state.mainOffer.payYourOwnPriceProduct.variantValue < this.state.payYourOwnPrice.value ? (
                          <div>
                            <b>{this.state.mainOffer.payYourOwnPriceProduct.additionalSupportMessage}</b>
                          </div>
                        ) : ''}
                        <div>
                        </div>
                      </div>
                    );
                  })()}
                </div>
  
                <hr />

                {this.state.mainOffer.description ? (
                  <div dangerouslySetInnerHTML={{
                    __html: this.state.mainOffer.description
                  }} />
                ) : ''}

                {this.state.bumpOffer ? (
                  <div class="offer__bump-offer">

                    <div class="offer__bump-offer__header">
                        <div class="offer__bump-offer__header__left">
                          <div style={{display: isLoadingImage.bump ? "block" : "none"}}>
                            <div class="loading-spinner"></div>
                          </div>
                          <div style={{display: isLoadingImage.bump ? "none" : "block"}}>
                            <img class="offer__bump-offer__header__image" 
                              src={this.state.bumpOffer.imageUrl} 
                              onLoad={() => {
                                isLoadingImage.bump = false;
                                this.setState({
                                  ...this.state,
                                })
                              }} />
                          </div>
                        </div>
                        <div class="offer__bump-offer__header__right">
                          <div class="offer__bump-offer__header__title-toggle">
                            <h3>{this.state.bumpOffer.title}</h3>
                            <div class="offer__bump-offer__header__title-toggle__toggle">
                              {/* Google Arrow */}
                              <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48">
                                <path d="M24 40 21.9 37.85 34.25 25.5H8V22.5H34.25L21.9 10.15L24 8L40 24Z"/>
                              </svg>
                              <label class="switch">
                                <input type="checkbox" disabled={this.state.isTimeExpired || this.state.isCheckingOut}
                                  checked={this.state.bumpOfferAccepted} 
                                  value={this.state.bumpOfferAccepted} 
                                  onInput={event => this.setState({
                                    ...this.state,
                                    bumpOfferAccepted: event.target.checked
                                  })}/>
                                <span class="slider round"></span>
                              </label>
                            </div>
                          </div>
                          <h4>Only {this.state.bumpOffer.originalPrice ? (<s>${this.state.bumpOffer.originalPrice}</s>) : ''} ${this.state.bumpOffer.offerPrice}!</h4>
                        </div>
                      </div>
                      
                      <hr />
                      
                      <div class="offer__bump-offer__description">
                      {this.state.bumpOffer.description ? (
                        <div dangerouslySetInnerHTML={{
                          __html: this.state.bumpOffer.description
                        }} />
                      ) : ''}
                    </div>
                  </div>
                ): ''}
              </div>
            );
          } else {
            let currentOffer = this.getCurrentOffer();
            renderOutput = (
              <div>
                <div class="offer__header">
                  {currentOffer.title ? (
                    <h1 class="offer-title">{currentOffer.title}</h1>
                  ) : ''}

                  {currentOffer.imageUrl ? (
                    <div>
                      <div style={{display: isLoadingImage.currentOffer ? "block" : "none"}}>
                        <div class="loading-spinner"></div>
                      </div>
                      <div style={{display: isLoadingImage.currentOffer ? "none" : "block"}}>
                        <img key={currentOffer.imageUrl} 
                          class="offer__image" 
                          src={currentOffer.imageUrl} 
                          onLoad={() => {
                            isLoadingImage.currentOffer = false
                            this.setState({
                              ...this.state
                            })
                          }} />
                      </div>
                    </div>
                  ) : ''}

                  <h2>Only {currentOffer.originalPrice ? (<s>${currentOffer.originalPrice}</s>) : ''} ${currentOffer.offerPrice}!</h2>
                </div>

                <hr />

                {currentOffer.description ? (
                  <div dangerouslySetInnerHTML={{
                    __html: currentOffer.description
                  }} />
                ) : ''}

              </div>
            );
          }
          return renderOutput;
        })()}

        <hr />

        {/* Continue / Checkout Buttons */}
        <div class="offer-controls">
          {this.state.mainOffer ? (() => {
            let renderOutput = [];

            // Show a loading spinner if we are checking out
            if (this.state.isCheckingOut) {
              renderOutput.push((
                <div>
                  <div class="loading-spinner"></div>
                  <h1>Going to Checkout...</h1>
                  {this.props.isPreview ? (
                    <h2>Preview Detected! Check your Devtools Console, or use this <a target="_blank" href={this.state.checkoutUrl}>Shopify Checkout Link</a>!</h2>
                  ): ''}
                  <hr />
                </div>
              ))
            }

            // Figure out if we need to show a select for any variant options
            let needsToSelectVariantOption = false;
            let addOfferOptions = (offer, callback) => {
              if (!needsToSelectVariantOption) {
                needsToSelectVariantOption = offer.shopifyProducts.some(shopifyProduct => {
                  return shopifyProduct.variantOptions.length > 0 && !shopifyProduct.selectedVariantOption;
                });
              }
              offer.shopifyProducts.forEach(shopifyProduct => {
                if (shopifyProduct.variantOptions.length > 0) {
                  renderOutput.push((
                    <div class="offer-controls__variant-select" key={`${shopifyProduct.variantId}-${this.state.offersIndex}`}>
                      <label>
                        <div>
                          Choose your {shopifyProduct.variantOptionName.toLowerCase()} of {shopifyProduct.productName}:
                        </div>
                        <div class="offer-controls__variant-select__radio-container">
                          
                          {(() => {
                            let variantOptions = [];

                            // Add the default variant for the Shopify Product
                            const allShopifyProductVariantOptions = [
                              {
                                variantName: shopifyProduct.variantName,
                                variantId: shopifyProduct.variantId,
                                shippingZoneVariantIds: shopifyProduct.shippingZoneVariantIds,
                              },
                              ...shopifyProduct.variantOptions
                            ];

                             allShopifyProductVariantOptions.forEach((variantOption, variantOptionIndex) => {
                              variantOptions.push((
                                <div class="offer-controls__variant-select__radio-container__variant">
                                  <input 
                                  type="radio"
                                  disabled={this.state.isTimeExpired || this.state.isCheckingOut}
                                  onChange={event => {
                                    const selectedVariantOption = allShopifyProductVariantOptions[event.target.value];
                                    shopifyProduct.selectedVariantOption = selectedVariantOption;
                                    this.setState({...this.state});
                                  }}
                                  id={variantOption.variantId} 
                                  name={shopifyProduct.variantOptionName.toLowerCase()} 
                                  value={variantOptionIndex} />
                                  <label for={variantOption.variantId}>{variantOption.variantName}</label>
                                </div>
                              ));
                            });
                            return variantOptions
                          })()}
                        </div>
                      </label>
                    </div>
                  ));
                }
              });
            }
            

            if (this.state.offersIndex > 0) {
              // Handle the current offer
              let currentOffer = this.getCurrentOffer();
              addOfferOptions(currentOffer);
            } else {
              // Handle the main and bump offer
              addOfferOptions(this.state.mainOffer);
              if (this.state.bumpOffer) {
                addOfferOptions(this.state.bumpOffer);
              }
            }

            // Figure out what continue and checkout buttons to show
            const hasUpsellAfterMain = this.state.offersIndex === 0 && this.state.upsellOffer;
            const hasOfferAfterUpsell = this.state.offersIndex === 1 && this.state.additionalUpsells.length > 0;
            const hasMoreAdditionalOffers = this.state.offersIndex > 1 && 
              this.state.offersIndex - 1 < this.state.additionalUpsells.length && 
              this.state.additionalUpsells.slice(this.state.offersIndex - 2).some(additionalUpsell => {
                if (!additionalUpsell.requiresBumpOffer) {
                  return true;
                } else if (additionalUpsell.requiresBumpOffer && this.state.bumpOfferAccepted) {
                  return true;
                }
                return false;
              });
            const hasDownsellAfterUpsell = this.state.offersIndex === 1 && this.state.downsellOffer;
            const hasDownsellForAdditionalOffer = this.state.offersIndex > 1 && 
              this.state.additionalUpsells[this.state.offersIndex - 2].isDownsellEnabled
            
            // Yes Button
            if (this.state.offersDownsellIndex === 0 && (hasUpsellAfterMain || hasOfferAfterUpsell || hasMoreAdditionalOffers)) {
              // Continue
              renderOutput.push((
                <button class="offer-controls__checkout-button" 
                  disabled={this.state.isTimeExpired || this.state.isCheckingOut || needsToSelectVariantOption}
                  onClick={event => {
                    this.acceptOffer();
                    document.body.scrollTop = document.documentElement.scrollTop = 0;
                  }}>{this.state.offersIndex === 0 ? 'Continue' : 'Add to Order & Continue'}</button>
              ));
            } else {
              if (this.state.discount && this.state.discount.length > 0 && !this.state.isCheckingOut) {
                renderOutput.push((
                  <div>
                    <div class="offer-controls__discount-info">
                      Your Discount Code:<br /><b>{this.state.discount}</b>
                    </div>
                    <hr />
                  </div>
                ));
              }
              renderOutput.push((
                <button class="offer-controls__checkout-button" disabled={this.state.isTimeExpired || this.state.isCheckingOut || needsToSelectVariantOption} 
                  onClick={() => {
                    this.acceptOffer();
                    this.shopifyCheckout();
                  }}>Add to Order & Checkout</button>
              ));
            }

            // No button
            if (this.state.offersIndex > 0) {
              if (this.state.offersDownsellIndex === 0 && (hasDownsellAfterUpsell || hasDownsellForAdditionalOffer)) {
                renderOutput.push((
                  <button class="offer-controls__checkout-button--decline" 
                    disabled={this.state.isTimeExpired || this.state.isCheckingOut}
                    onClick={event => {
                      // Add the current checkout variant state to the undo stack
                      cartStack.push({
                        offers: null,
                        checkoutVariants: [
                          ...this.state.checkoutVariants
                        ]
                      });
                      isLoadingImage.currentOffer = true;
                      this.setState({
                        ...this.state, 
                        offersDownsellIndex: this.state.offersDownsellIndex + 1
                      });
                      document.body.scrollTop = document.documentElement.scrollTop = 0;
                    }}>No Thanks & Continue</button>
                ));
              } else {
                renderOutput.push((
                  <button class="offer-controls__checkout-button--decline" disabled={this.state.isTimeExpired || this.state.isCheckingOut} onClick={() => this.shopifyCheckout()}>No Thanks & Checkout</button>
                ));
              }
            }

            // Go Back
            if (this.state.offersIndex > 0) {
              renderOutput.push((
                <div><button class="offer-controls__checkout-button--back" disabled={this.state.isTimeExpired || this.state.isCheckingOut} onClick={() => this.undoOffer()}>{'< Go Back'}</button></div>
              ));
            }


            return renderOutput;
          })() : ''}
          <div class="offer-controls__checkout-badges">
            {/* Shopify: https://shopify.dev/themes/trust-security/security-badges */}
             <img
              src="https://cdn.shopify.com/s/images/badges/shopify-secure-badge-white.svg"
              alt="Shopify secure badge" />
          </div>
        </div>


        {/* Total Price Table */}
        {this.state.mainOffer ? (
          <div class="cart-total">
            <h2>Shopping Cart Preview</h2>
            <table class="cart-total__table">
              <tbody>
                <tr>
                  <td>{this.state.mainOffer.shoppingCartTitle ? this.state.mainOffer.shoppingCartTitle : this.state.mainOffer.title}</td>
                  <td>${parseFloat(this.state.mainOffer.offerPrice).toFixed(2)}</td>
                </tr>
                {this.state.payYourOwnPrice && this.state.payYourOwnPrice.value > 0 ? (
                  <tr>
                    <td>Pay Your Own Price</td>
                    <td>${parseFloat(this.state.payYourOwnPrice.value).toFixed(2)}</td>
                  </tr>
                ) : ''}
                {this.state.bumpOfferAccepted ? (
                  <tr>
                    <td>{this.state.bumpOffer.shopifyProducts[0].productName}</td>
                    <td>${parseFloat(this.state.bumpOffer.offerPrice).toFixed(2)}</td>
                  </tr>
                ) : ''}
                {(() => {
                  // Anything else in the cart stack besides the main offer
                  const upsellCartStackElements = [];
                  getUpsellOffersInCartStack().forEach(upsellOfferInCartStack => {
                    upsellCartStackElements.push((
                      <tr>
                        <td>{upsellOfferInCartStack.shoppingCartTitle ? upsellOfferInCartStack.shoppingCartTitle : upsellOfferInCartStack.title}</td>
                        <td>${parseFloat(upsellOfferInCartStack.offerPrice).toFixed(2)}</td>
                      </tr>
                    ));
                  })
                  return upsellCartStackElements;
                })()}
                <tr>
                  <td><b>Total</b></td>
                  <td><b>${(() => {
                    let totalPrice = parseFloat(this.state.mainOffer.offerPrice);

                    if (this.state.payYourOwnPrice && this.state.payYourOwnPrice.value > 0) {
                      totalPrice += parseFloat(this.state.payYourOwnPrice.value)
                    }
                    
                    if (this.state.bumpOfferAccepted) {
                      totalPrice += parseFloat(this.state.bumpOffer.offerPrice)
                    }

                    getUpsellOffersInCartStack().forEach(upsellOfferInCartStack => {
                      totalPrice += parseFloat(upsellOfferInCartStack.offerPrice)
                    });

                    return totalPrice.toFixed(2);
                  })()}</b></td>
                </tr>
              </tbody>
            </table>
            <p><b>(Plus any additional shipping and handling)</b></p>
            {(() => {
                if (this.state.discount && this.state.discount.length) {
                  if (this.state.discount.length === 1) {
                    return (
                      <p class="cart-total__discount-code">
                        Use this discount code at checkout for the prices above:
                        <br />
                        <b>{this.state.discount}</b>
                        <br />
                        (Should be automatically applied)
                      </p>
                    );
                  } else {
                    return (
                      <p class="cart-total__discount-code">
                        Use these discount codes at checkout for the prices above:
                        <br/>
                        <b>{this.state.discount.join(' , ')}</b>
                        <br />
                        (Should be automatically applied)
                      </p>
                    );
                  }
                }
                return '';
              })()}
            {(() => {
              if (this.state.privacyPolicyLink || this.state.contactUsLink) {
                return (
                  <div class="site-info">
                    {this.state.privacyPolicyLink ? (
                      <a target="_blank" href={this.state.privacyPolicyLink}>Privacy Policy</a>
                    ): ''}
                    {this.state.contactUsLink ? (
                      <a target="_blank" href={this.state.contactUsLink}>Contact Us</a>
                    ): ''}
                  </div>
                )
              }
              return ''
            })()}
            </div>
          ) : ''}
      </main>
      </div>
    );
  }
}
