import { h, render, Component } from 'preact';
import 'normalize.css/normalize.css';
import 'water.css/out/water.css';
import './index.css';

import { DoubloonFunnel } from './doubloon-funnel.js';

class App extends Component {

  constructor() {
    super();

    // Get all of our relevant search params
    let searchParams = new URLSearchParams(document.location.search);
    let doubloonJsonUrl = searchParams.get('json');
    let doubloonPreview = searchParams.get('preview');
    let discount = searchParams.getAll('discount');
    let expireTime = searchParams.get('expiretime');
    let brandColor = searchParams.get('brandcolor');
    let doubloonRef = searchParams.get('doubloon-ref');

    this.state = {
      isLoadingJson: true,
      isLoadingCheckout: false,
      errorMessage: null,
      doubloonJson: null,
      doubloonJsonUrl,
      doubloonPreview,
      discount,
      expireTime,
      brandColor,
      doubloonRef
    };
  }

  componentDidMount() {
    // Check the JSON url param
    const jsonUrl = this.state.doubloonJsonUrl
    if (jsonUrl) {
      fetch(jsonUrl).then(response => {
        return response.json();
      }).then(data => {
        this.setState({
          ...this.state,
          isLoadingJson: false,
          doubloonJson: data
        });
      }).catch(err => {
        this.setState({
          ...this.state,
          isLoadingJson: false,
          errorMessage: 'Could not fetch the configuration JSON. Please let the person who sent you this link know!'
        });
      });

      return;
    }    

    // Check if this is a preview
    let isPreview = this.state.doubloonPreview;
    let previewJson = localStorage.getItem('doubloon-preview-json');
    if (isPreview && previewJson) {
      setTimeout(() => {
        // Log the preview json
        let parsedPreviewJson = JSON.parse(previewJson);
        console.log('Parsed Preview JSON', parsedPreviewJson);
        this.setState({
          ...this.state,
          isLoadingJson: false,
          doubloonJson: parsedPreviewJson
        });
      }, 1000);
    } else {
      // Show an Error
      this.setState({
        ...this.state,
        isLoadingJson: false,
        errorMessage: 'Could not find get the configuration. Make sure you clicked the link exactly as it was sent to you!'
      });
    }

  }

  render() {
    return (
      <div>
        
        {/* Brand Colors */}
        {this.state.brandColor ? (
          <style>{`
            :root {
              --brand-color: #${this.state.brandColor}
            }
          `}</style>
        ) : ''}

        {/* Messages to the User while parsing Doubloon JSON */}
        {(this.state.isLoadingJson || this.state.errorMessage) ? (() => {

          let htmlMessage = (
            <div class="full-screen-message__container">
              <div class="loading-spinner"></div>
              <h1>Loading...</h1>
            </div>
          );
          if (this.state.errorMessage) {
            htmlMessage = (
              <div class="full-screen-message__container">
                <div class="error-icon">🛑</div>
                <h1>Error: {this.state.errorMessage}</h1>
              </div>
            );
          }

          return (
            <div class="full-screen-message">
              {htmlMessage}
            </div>
          );
        })() : ''}

        {/* Our actual sales funnel */}
        {this.state.doubloonJson ? (() => {
          return (
            <DoubloonFunnel isPreview={this.state.doubloonPreview} json={this.state.doubloonJson} discount={this.state.discount} expireTime={this.state.expireTime} doubloonRef={this.state.doubloonRef} />
          );
        })() : ''}
      </div>
    );
  }
}

render(<App />, document.body);
