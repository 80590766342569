export class DoubloonAnalytics {
  constructor(doubloonAnalyticsConfig) {

    this.doubloonAnalyticsConfg = null;
    this.totalValue = 0;
    this.facebook = {
      shouldTrack: false,
      contents: []
    }
    this.google = {
      shouldTrack: false,
      items: []
    }
    this.reddit = {
      shouldTrack: false,
      itemCount: 0
    }
    this.snapchat = {
      shouldTrack: false,
      numberOfItems: 0,
      itemIds: [],
    }
    
    if (!doubloonAnalyticsConfig) {
      return;
    }

    // Facebook
    if (doubloonAnalyticsConfig.facebookPixelId) {
      // Add our Facebook Pixel Id
      // https://developers.facebook.com/docs/meta-pixel/get-started
      const fbPixelScript = document.createElement('script');
      fbPixelScript.type = 'text/javascript';
      fbPixelScript.innerHTML = `
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '${doubloonAnalyticsConfig.facebookPixelId}');
        fbq('track', 'PageView');
      `;
      document.head.append(fbPixelScript);
      this.facebook.shouldTrack = true;
    }

    // Google
    if (doubloonAnalyticsConfig.googleAnalyticsTrackingId) {
      // Add our Google Analytics Id
      const googleAsyncScript = document.createElement('script');
      googleAsyncScript.async = true;
      googleAsyncScript.src = `https://www.googletagmanager.com/gtag/js?id=${doubloonAnalyticsConfig.googleAnalyticsTrackingId}`;
      const googleGtagScript = document.createElement('script');
      googleGtagScript.type = 'text/javascript';
      googleGtagScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', '${doubloonAnalyticsConfig.googleAnalyticsTrackingId}');
      `;
      document.head.append(googleAsyncScript);
      document.head.append(googleGtagScript);
      this.google.shouldTrack = true;
    }

    // Reddit
    if (doubloonAnalyticsConfig.redditPixelId) {
      // Add our Reddit Pixel ID
      // https://redditinc.force.com/helpcenter/s/article/Install-the-Reddit-Pixel-on-your-website
      const redditPixelScript = document.createElement('script');
      redditPixelScript.type = 'text/javascript';
      redditPixelScript.innerHTML = `
      !function(w,d){if(!w.rdt){var p=w.rdt=function(){p.sendEvent?p.sendEvent.apply(p,arguments):p.callQueue.push(arguments)};p.callQueue=[];var t=d.createElement("script");t.src="https://www.redditstatic.com/ads/pixel.js",t.async=!0;var s=d.getElementsByTagName("script")[0];s.parentNode.insertBefore(t,s)}}(window,document);rdt('init','${doubloonAnalyticsConfig.redditPixelId}', {"optOut":false,"useDecimalCurrencyValues":true});rdt('track', 'PageVisit');
      `;
      document.head.append(redditPixelScript);
      this.reddit.shouldTrack = true;
    }

    // Snapchat
    if (doubloonAnalyticsConfig.snapchatPixelId) {
      // Add our Snapchat Pixel ID
      // https://businesshelp.snapchat.com/s/article/pixel-direct-implementation?language=en_US
      const snapchatPixelScript = document.createElement('script');
      snapchatPixelScript.type = 'text/javascript';
      snapchatPixelScript.innerHTML = `
(function(e,t,n){if(e.snaptr)return;var a=e.snaptr=function()
{a.handleRequest?a.handleRequest.apply(a,arguments):a.queue.push(arguments)};
a.queue=[];var s='script';r=t.createElement(s);r.async=!0;
r.src=n;var u=t.getElementsByTagName(s)[0];
u.parentNode.insertBefore(r,u);})(window,document,
'https://sc-static.net/scevent.min.js');

snaptr('init', '${doubloonAnalyticsConfig.snapchatPixelId}');
snaptr('track', 'PAGE_VIEW');
      `;
      document.head.append(snapchatPixelScript);
      this.snapchat.shouldTrack = true;
    }


  }

  _getOfferTitle(offer) {
    if (!offer) {
      return '(No Offer)';
    }

    let title = offer.shoppingCartTitle;
    if (!title || title.length === 0) {
      title = offer.title;
    }
    return title;
  }

  doubloonLoad(name, discount, storeUrl) {
    if (this.google.shouldTrack && gtag) {
      // Google Universal Analytics Event
      // https://developers.google.com/tag-platform/devguides/events#gtag.js_1
      gtag('event', `doubloon-load`, {
        event_category: 'doubloon',
        event_label: `Name: ${name} | Discount: ${discount} | Store URL: ${storeUrl}`
      });
    }
  }

  acceptOffer(offer) {
    if (this.google.shouldTrack && gtag) {
      // Google Universal Analytics Event
      // https://developers.google.com/tag-platform/devguides/events#gtag.js_1
      gtag('event', `accept-offer`, {
        event_category: 'doubloon',
        event_label: `${this._getOfferTitle(offer)}`
      });
    }
  }

  undoOffer(offer) {
    if (this.google.shouldTrack && gtag) {
      // Google Universal Analytics Event
      // https://developers.google.com/tag-platform/devguides/events#gtag.js_1
      gtag('event', `undo-offer`, {
        event_category: 'doubloon',
        event_label: `${this._getOfferTitle(offer)}`
      });
    }
  }

  addToCart(offer) {
    let value = parseFloat(offer.offerPrice);
    this.totalValue += value;
    let title = this._getOfferTitle(offer)

    // Facebook
    if (this.facebook.shouldTrack && fbq) {
      let contents = [];
      offer.shopifyProducts.forEach(shopifyProduct => {
        contents.push({
          id: shopifyProduct.variantId,
          quantity: shopifyProduct.quantity
        });
      });
      this.facebook.contents = [
        ...this.facebook.contents,
        ...contents
      ];

      fbq('track', 'AddToCart', {
        content_name: title,
        currency: 'USD',
        value,
        contents
      });
    }

    // Google
    if (this.google.shouldTrack && gtag) {
      let items = [];
      offer.shopifyProducts.forEach(shopifyProduct => {
        items.push({
          item_id: shopifyProduct.variantId,
          item_name: shopifyProduct.productName,
          quantity: shopifyProduct.quantity
        });
      });
      this.google.items = [
        ...this.google.items,
        ...items
      ];

      // Google Universal Analytics Event
      // https://developers.google.com/tag-platform/devguides/events#gtag.js_1
      gtag('event', 'add-to-cart', {
        event_category: 'doubloon',
        event_label: title,
        value: parseInt(value)
      });

      // Google Ecommerce Event
      // https://developers.google.com/analytics/devguides/collection/ga4/reference/events#add_to_cart_item
      gtag("event", "add_to_cart", {
        currency: 'USD',
        value,
        items
      });
    }

    // Reddit
    // It looks like Reddit Add to Cart is more equivalent to Initiate Checkout, since it just
    // Wants number of items and value. Which Initiate checkout usually only wants
    // And we could make an equivalent Reddit Custom event: https://redditinc.force.com/helpcenter/s/article/Reddit-Pixel-Custom-Events 
    // But I don't think it's worth it, since we don't neccesarily need it
    // Since we still need to keep track in JS of the cart, let's do that
    if (this.reddit.shouldTrack && rdt) {
      let offerItemCount = 0;
      offer.shopifyProducts.forEach(shopifyProduct => {
        offerItemCount += parseInt(shopifyProduct.quantity);
      });
      this.reddit.itemCount += offerItemCount;
    }

    // Snapchat
    if (this.snapchat.shouldTrack && snaptr) {
      let offerItemCount = 0;
      let variantIds = [];
      offer.shopifyProducts.forEach(shopifyProduct => {
        offerItemCount += parseInt(shopifyProduct.quantity);
        variantIds.push(shopifyProduct.variantId)
      });
      this.snapchat.numberOfItems += offerItemCount;
      this.snapchat.itemIds = this.snapchat.itemIds.concat(variantIds);
    }
  }

  initiateCheckout() {
    // Facebook
    if (this.facebook.shouldTrack && fbq) {
      let numItems = 0;
      this.facebook.contents.forEach(content => {
        numItems += parseInt(content.quantity);
      });
      fbq('track', 'InitiateCheckout', {
        currency: 'USD',
        value: this.totalValue,
        num_items: numItems,
        contents: this.facebook.contents
      });
    }

    // Google
    if (this.google.shouldTrack && gtag) {
      // Google Universal Analytics Event
      // https://developers.google.com/tag-platform/devguides/events#gtag.js_1
      gtag('event', 'begin-checkout', {
        event_category: 'doubloon',
        event_label: `$${this.totalValue}`,
        value: parseInt(this.totalValue)
      });

      // Google Ecommerce Event
      // https://developers.google.com/analytics/devguides/collection/ga4/reference/events#add_to_cart_item
      gtag("event", "begin_checkout", {
        currency: 'USD',
        value: this.totalValue,
        items: this.google.items
      });
    }

    // Reddit
    // https://redditinc.force.com/helpcenter/s/article/Reddit-Pixel-Event-Metadata
    if (this.reddit.shouldTrack && rdt) {
      rdt('track', 'AddToCart', {
        itemCount: this.reddit.itemCount,
        value: this.totalValue,
        currency: 'USD'
      });
    }

    // Snapchat
    if (this.snapchat.shouldTrack && snaptr) {
      snaptr('track', 'ADD_CART', {
        'number_items': this.snapchat.numberOfItems,
        'item_ids': this.snapchat.itemIds,
        currency: 'USD',
        price: this.totalValue
      });
      snaptr('track','START_CHECKOUT');
    }
  }
}
